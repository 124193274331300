<template>
  <div class=" max-w-2xl mx-auto p-12">
    <div class=" border-b pb-6">
    <div>
      <div class="border-2 p-6 border-green-500  rounded-md">
        <div class=" text-lg black95 flex items-center">
          <div class="checkmark mr-4"></div>
          <div>
            Details validated successfully!
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="flex items-center mt-4">
        <img class=" w-5 mr-2" :src="bookSrc" alt="">
        <div class=" black95 font-600 text-base">Beneficiary Details</div>
      </div>
      <div class=" grid grid-cols-2 gap-2 mt-4 pl-4">
        <div>Name:</div>
        <div>{{beneficiaryFormData.name}}</div>
        <div>Email:</div>
        <div>{{beneficiaryFormData.email}}</div>
        <div>Address:</div>
        <div>{{beneficiaryFormData.beneficiary_address}}</div>
        <div>Country:</div>
        <div>{{beneficiaryFormData.beneficiary_country}}</div>
      </div>
    </div>
    <div class="flex items-center mt-4">
        <img class=" w-5 mr-2" :src="concilSrc" alt="">
        <div class=" black95 font-600 text-base">Beneficiary Bank Details</div>
      </div>
      <div class=" grid grid-cols-2 gap-2 mt-4 pl-4">
        <div>Currency:</div>
        <div>{{beneficiaryFormData.currency}}</div>
      </div>
      <div>
      <div class="pl-4">Priority:</div>
        <div class=" grid grid-cols-2 gap-1 mt-2 px-12">
        <div>Beneficiary Type:</div>
        <div class=" capitalize">{{beneficiaryFormData.beneficiary_type}}</div>
        <div>Address:</div>
        <div>{{beneficiaryFormData.beneficiary_address}}</div>
        <div>City:</div>
        <div>{{beneficiaryFormData.beneficiary_city}}</div>
        <div>Beneficiary Country:</div>
        <div>{{beneficiaryFormData.beneficiary_country}}</div>
        <div>Name:</div>
        <div>{{beneficiaryFormData.bank_account_holder_name}}</div>
        <div>BIC/SWIFT:</div>
        <div>{{beneficiaryFormData.bic_swift}}</div>
        <div>Account Number:</div>
        <div>{{ beneficiaryFormData.account_number }}</div>
      </div>
      </div>
  </div>
   <div class="flex justify-evenly pt-4  max-w-3xl">
      <a-button @click="handleBack" type="danger" >
        上一步
      </a-button>
      <a-button :loading="creatBtnLoading" :disable="creatBtnDisabled" @click="handleCreate" type="primary" >
        创建收款人
      </a-button>
    </div>
  </div>
</template>

<script>
import rotateSrc from '@/assets/icon/rotate.png'
import moneyExchangeSrc from '@/assets/icon/money-exchange.png'
import concilSrc from '@/assets/icon/council.png'
import bookSrc from '@/assets/icon/book.png'
import { CreateCCBeneficiary } from '@/api/common'
import { mapGetters, mapState } from 'vuex'
import fileSrc from '@/assets/icon/file-text-fill.png'
export default {
  created () {
    this.$emit('update:current', 2)
  },
  data () {
    return {
      rotateSrc,
      concilSrc,
      bookSrc,
      moneyExchangeSrc,
      fileSrc,
      creatBtnLoading: false,
      creatBtnDisabled: false
    }
  },
  methods: {
    handleBack () {
      this.$router.go(-1)
    },
    handleCreate () {
      if (this.creatBtnLoading || this.creatBtnDisabled) return
      this.creatBtnLoading = true
      this.creatBtnDisabled = true
      CreateCCBeneficiary({ ...this.GetFormData, customer_id: this.customer.id }).then(res => {
        this.creatBtnLoading = false
        this.creatBtnDisabled = false
        this.$router.push('/user/beneficiary/add/step-four')
      }).catch(err => {
        console.log(err)
        this.creatBtnLoading = false
        this.creatBtnDisabled = false
        console.log(err.message)
      })
      // this.$router.push('/user/beneficiary/add/step-four')
    }
  },
  computed: {
    beneficiaryFormData () {
      return this.$store.state.ccBeneficiary.formData
    },
    ...mapGetters('ccBeneficiary', ['GetFormData']),
    ...mapState('cc', ['customer'])
  }

}
</script>

<style scoped>
.checkmark {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
}

.checkmark::after {
  content: "";
  display: block;
  width: 12px;
  height: 20px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 10%;
  left: 50%;
}
.grid_layout{
  grid-template-columns: 1fr 1fr;
}
</style>
